<template>
	<div class="cart-lunch-row">
		<div class="cart-lunch-row__descr">
			<div class="cart-lunch-row__img">
				<img
					v-if="!lunch.lanches"
					src="@/assets/img/cartLunch.webp"
					alt="Lunch"
				>
				<img
					v-if="lunch.lanches"
					src="@/assets/img/cartLunchSev.webp"
					alt="Lunch"
				>
			</div>
			<div class="cart-lunches-row__descr-wr">
				<h2 class="cart-lunch-row__name">{{ lunch.name }}</h2>
				<span
					class="cart-lunch-row__meal-type"
					v-if="lunch.lanches"
				>
					{{ lunch.lanch.name }}
				</span>
				<span
					v-else
					class="cart-lunch-row__meal-type"
				>
					<template v-for="meal in lunch.meals">
						{{ meal.name }}{{ meal === lunch.meals[lunch.meals.length - 1] ? '' : ', ' }}
					</template>
				</span>
				<span class="cart-lunch-row__meal-type">{{ lunch.weight }} гр. / {{ lunch.kkal }} Ккал</span>
			</div>
		</div>
		<div class="cart-lunch-row__right">
			<div class="cart-lunch-row__cost">
				<Counter
					:value="+lunch.count"
					:checkCounterBetweenChange="checkCartBeforeAddProduct"
					@change="setLanchCount"
				/>
				<div
					class="cart-lunch-row__price-for-one"
					v-if="getLunchPriceWithBoxes"
				>
					{{ getLunchPriceWithBoxes }} ₽/шт
				</div>
			</div>
			<div class="cart-lunch-row__total">
				<span class="cart-lunch-row__total_value">{{ getProductTotalPrice }} ₽</span>
				<div
					class="cart-lunch-row__total_mark"
					v-if="getLanchBallsCount(lunch) && currentBasket?.owner"
				>
					+{{ getLanchBallsCount(lunch) }}
					<img
						src="~@/assets/img/icons/bonus.svg"
						alt="Bonus"
					/>
				</div>
			</div>
			<img
				@click="openRemovePopup()"
				src="@/assets/img/close.svg"
				class="cart-lunch-row__remove"
				alt="Remove"
			/>
		</div>
		<ConfirmationPopup
			:visible="addPopupVisible"
			:title="`Вы уверены, что хотите удалить комбо “${lunch.name || ''}” из корзины?`"
			@closePopup="addPopupVisible = false"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="setLanchCount(0)"
				>Удалить
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="addPopupVisible = false"
				>Отмена
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>

<script>
import Counter from '@/components/ui/Counter.vue';
import WordEnding from '@/mixins/returnWordEnding.js';
import {addThousandSeparator} from '@/helpers';
import qs from 'qs'
import {mapGetters} from "vuex";
import {useChangeCartItem} from "@/composables";

export default {
	components: {Counter, ConfirmationPopup: () => import('@/components/popup/ConfirmationPopup.vue')},
	setup()
	{
		const {checkCartBeforeAddProduct} = useChangeCartItem();

		return {checkCartBeforeAddProduct};
	},
	props:
		{
			lunch: Object,
		},
	mixins: [WordEnding],
	data()
	{
		return {
			addPopupVisible: false,
		}
	},
	computed:
		{
			...mapGetters({
				currentBasket: "getCurrentBasket",
				isDone: "getDone",
			}),
			getLunchPriceWithBoxes()
			{
				let lunchPriceWhithBoxes = this.lunch.lanch.price + this.$store.getters.getLunchBoxesPrice(this.lunch)
				return addThousandSeparator(lunchPriceWhithBoxes)
			},
			/**
			 * Достать сумму для продуктов учитывая количество
			 */
			getProductTotalPrice()
			{
				//была переменная getLunchPriceWithBoxes но она не работала выводила NaN
				let productTotalPrice = (this.lunch.lanch.price + this.$store.getters.getLunchBoxesPrice(this.lunch)) * this.lunch.count
				return addThousandSeparator(productTotalPrice);
			},
		},
	methods:
		{
			openRemovePopup()
			{
				if (!this.checkCartBeforeAddProduct()) return;

				return this.addPopupVisible = !this.addPopupVisible;
			},
			getLanchBallsCount(lanch)
			{
				if (lanch.lanch.balls == 0)
					return false;
				return lanch.lanch.balls * lanch.count;
			},
			async setLanchCount(count)
			{
				try
				{
					const params = qs.stringify({
						'cartLanchId': this.lunch?.id,
						'cartId': this.lunch?.cart_id,
						'count': count,
						'cartTypeId': this.currentBasket?.id
					});

					const {data} = await this.$axios.post('/cart/setLanchCount/', params);

					if (data.success) await this.getCart();
				} catch (error)
				{
					throw new Error(error)
				}
			},
		},
}
</script>

<style lang="scss">
.cart-lunch-row
{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-lunch-row__descr
{
	display: flex;
	align-items: flex-start;
	gap: 24px;
}

.cart-lunch-row__img
{
	width: 96px;
	height: 96px;
	border-radius: 20px;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.cart-lunches-row__descr-wr
{
	display: flex;
	flex-direction: column;
	gap: 8px;

	margin-top: 10px;
}

.cart-lunch-row__name
{
	font-family: $mainFontBoldNew;
	font-size: 18px;
	line-height: 24px;
}

.cart-lunch-row__meal-type
{
	font-family: $mainFontNew;
	font-size: 12px;
	line-height: 16px;
	color: $greyLightText;
}

.cart-lunch-row__right
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	max-width: 370px;
	flex: 1;

	margin-top: 10px;
}

.cart-lunch-row__cost
{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.cart-lunch-row__price-for-one
{
	background-color: $greyBackground;
	border-radius: 2px;
	padding: 4px 6px;
	font-size: 14px;
	line-height: 20px;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.cart-lunch-row__total
{
	display: flex;
	align-items: center;
	gap: 10px;
}

.cart-lunch-row__total_value
{
	white-space: nowrap;
	font-family: $mainFontBoldNew;
	font-size: 24px;
	line-height: 36px;
	color: $textColor;
}

.cart-lunch-row__total_mark
{
	display: flex;
	align-items: center;
	justify-content: center;
	background: $green;
	border-radius: 4px;
	padding: 2px 4px;

	font-family: $mainFontBoldNew;
	font-size: 14px;
	line-height: 18px;
	color: white;
}

.cart-lunch-row__remove
{
	margin-top: 6px;
	cursor: pointer;
}
</style>